// stylelint-disable

@import url("https://use.typekit.net/owq0wpf.css");
@import url("https://use.typekit.net/mlt3yts.css");
// stylelint-enable

//New Fonts
$font-main: sofia-pro,sans-serif;
$font-alt: ltc-goudy-oldstyle-pro, serif;

